import Carousel from 'react-bootstrap/Carousel';

import { Link } from 'react-router-dom';

function LoadPayments({payments,handleShow}) {
    

  return (
    <Carousel keyboard={true} indicators={true}>
        {payments && payments.map((payment,key) => (
            <Carousel.Item key={key}>
                <div key={key} className="row m-0 mb-0 ml-4 mr-4 pl-2 pr-2">
                    <div className="col-12 m-0 mb-2 p-0">
                        <div className="card-box mb-1">
                        {/* MonthlyArrears,MonthlyExcess,MonthlyRefund,MonthlyBilled,MonthlyPaid,MonthlyBalance */}

                            <div className='card-icon'>
                                <span className="card-box-icon bg-light elevation-1 mt-1 bold" style={{"width":"66px"}}><small className='bold'>{payment.plotcode}<br/>
                                <span className="card-box-text"><span className='text-success bold'>{payment.totalbillshse}/<span className='title-sm bold'>{payment.totalhouseshse}</span> </span></span>
                                </small> </span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"66px"}}> <i className='fa fa-envelope text-sm'></i> <small>Sent:{payment.totalbillsmsghse}</small></span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"66px"}}><small>Rent:<br/><small className='text-lime bold'>{new Number(payment.rent).toFixed(2)}</small></small></span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"66px"}}><small>Waterbill:<br/><small className='text-lime bold'>{new Number(payment.waterbill).toFixed(2)}</small></small></span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"66px"}}><small>Garbage:<br/><small className='text-lime bold'>{new Number(payment.garbage).toFixed(2)}</small></small></span>
                            </div>
                            <div className="card-box-content">
                                <p className='text-center bg-info text-white elevation-1 border-light p-2 mb-2' style={{"textAlign": "center","whiteSpace": "nowrap","fontSize":"8px"}}>{payment.plotname}</p>
                                <span className="card-box-text"><span className='title-sm bold'>Balance: </span> <span className='text-success bold'>{payment.MonthlyBalance} </span></span>
                                <span className="card-box-text"><span className='title-sm bold'>Rent D: </span> <span className='text-success bold'>{payment.hsedeposit} </span></span>
                                <span className="card-box-text"><span className='title-sm bold'>Water D: </span> <span className='text-success bold'>{payment.water} </span></span>
                                <span className="card-box-text"><span className='title-sm bold'>KPLC D: </span> <span className='text-success bold'>{payment.kplc} </span></span>
                                <span className="card-box-text"><span className='title-sm bold'>Lease: </span> <span className='text-success bold'>{payment.lease} </span></span>
                                {/* <span className="card-box-text"><span className='title-sm bold'>Billed Waterbill: </span> Kshs. {new Number(payment.totals).toFixed(2)} </span> */}
                                <span className='card-box-text'>
                                    <div className="card-box-links m-0 p-1">
                                        <div className="row m-0 p-0">
                                            <div className='col-4 p-0 m-0'>
                                                <Link to={'/properties/update/payments/'+payment.id+'/'+payment.month} className="p-0 m-0 pl-1 pr-1 btn btn-outline-primary"> <i className='fa fa-upload text-sm'></i> </Link>
                                            </div>
                                            
                                            <div className='col-4 p-0 m-0'>
                                                <Link to={'/properties/update/payments/'+payment.id+'/'+payment.month} className="p-0 m-0 pl-1 pr-1 btn btn-outline-success"> <i className='fa fa-envelope text-sm'></i> </Link>
                                            </div>

                                            <div className='col-4 p-0 m-0'>
                                                <Link to="#" className="p-0 m-0 pl-1 pr-1 btn btn-outline-secondary" onClick={()=>{handleShow(payment)}}> <i className='fa fa-download text-sm'></i> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div className='card-icon'>
                            {/* MonthlyPaidEquity,MonthlyPaidCoop,MontlyOthersPaid */}
                                <span className="card-box-icon bg-light elevation-1 mt-1 p-1" style={{"width":"66px"}}><small className='bold'>Billed:<br/><small className='bold'>{payment.MonthlyBilled}</small></small></span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"66px"}}><small>Equity:<br/><small className='bold'>{payment.MonthlyPaidEquity}</small></small></span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"66px"}}><small>CO-OP:<br/><small className='bold'>{new Number(payment.MonthlyPaidCoop).toFixed(2)}</small></small></span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"66px"}}><small>Others:<br/><small className='bold'>{payment.MontlyOthersPaid}</small></small></span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"66px"}}><small className='bold'>Paid:<br/><small className='bold'>{payment.MonthlyPaid}</small></small></span>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Carousel.Item>
        ))
        }
    </Carousel>
  );
}

export default LoadPayments;