import {Link, Navigate, useNavigate} from 'react-router-dom';

function WaterbillMessageTable({waterbill,no,preview,uploadwaterbill,allchecked,handleChange,sendMessage}) {
    
  return (
    <div className="col-12 m-0 p-0" >
        <div className="bg-white m-0 mb-2 elevation-3 border-5 p-1" >
            <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0 text-left">
                    <span className="text-dark" style={{"fontSize":"10px"}}>
                        <b>{no+1}. {waterbill.tenantfname}, {waterbill.housename}, {waterbill.phone}
                            {waterbill.messageSent !=null?
                                <button className="btn btn-outline-warning float-right text-xs m-1 p-0 pr-1 pl-1" onClick={()=>{sendMessage(waterbill)}}><i className="fa fa-history"></i> Resend</button>
                            :
                                <button className="btn btn-outline-info float-right text-xs m-1 p-0 pr-1 pl-1" onClick={()=>{sendMessage(waterbill)}}><i className="fa fa-paper-plane"></i> Send</button>
                            }
                        </b>
                        
                        {waterbill.messageSent !=null?
                            <i className="float-right m-1 p-0 pr-1 pl-1 text-muted">{waterbill.messageSent} {waterbill.status == null?'':<span className="text-sm">({waterbill.status})</span>}</i>
                            :
                            <i className="float-right m-1 p-0 pr-1 pl-1 text-muted">Not sent</i>
                        }

                    </span>
                </div>
            </div>
        
            <div className="">
                <div className="row m-0 p-0">
                    <div className="col-12 m-0 p-0">
                        {waterbill.messageSent !=null?
                            <div className={`unwaterbillvaluesdiv p-1 text-sm border-5 ${(uploadwaterbill.upwaterbill.filter( (f) => f === waterbill.phone+'/'+waterbill.house+'/WATER BILL: Greetings '+waterbill.housename+' :'+waterbill.monthdate+';Previous:'+waterbill.previous+';Current:'+waterbill.current+';UnitCost Kshs.'+waterbill.cost+';Units:'+waterbill.units+';'+(waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':'')+'Total Kshs.'+(waterbill.total+waterbill.total_os)+'.Thank You')).length ?'bg-secondary text-info':'bg-warning text-dark'}`} >
                                {allchecked? 
                                    <label className="selwaterbill-1 col-12 m-0 p-1">
                                        <input type="checkbox" 
                                            className="selectedwaterbilltenants mr-1" 
                                            name="waterbillvalues[]"
                                            checked={allchecked}
                                            value={waterbill.phone+'/'+waterbill.house+'/WATER BILL: Greetings '+waterbill.housename+' :'+waterbill.monthdate+';Previous:'+waterbill.previous+';Current:'+waterbill.current+';UnitCost Kshs.'+waterbill.cost+';Units:'+waterbill.units+';'+(waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':'')+'Total Kshs.'+(waterbill.total+waterbill.total_os)+'.Thank You'}
                                            onChange={handleChange} />                                                                                                                                                                                                                                      
                                            WATER BILL: Greetings {waterbill.housename} :{waterbill.monthdate}; Previous:{waterbill.previous}; Current:{waterbill.current}; Cost Kshs.{waterbill.cost}; Units:{waterbill.units}; {waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':''} Total Kshs.{waterbill.total+waterbill.total_os}.
                                    </label>
                                :
                                    <label className="selwaterbill-1 col-12 m-0 p-1">
                                        <input type="checkbox" 
                                            className="selectedwaterbilltenants mr-1" 
                                            name="waterbillvalues[]"
                                            checked={(uploadwaterbill.upwaterbill.filter( (f) => f === waterbill.phone+'/'+waterbill.house+'/WATER BILL: Greetings '+waterbill.housename+' :'+waterbill.monthdate+';Previous:'+waterbill.previous+';Current:'+waterbill.current+';UnitCost Kshs.'+waterbill.cost+';Units:'+waterbill.units+';'+(waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':'')+'Total Kshs.'+(waterbill.total+waterbill.total_os)+'.Thank You')).length}
                                            value={waterbill.phone+'/'+waterbill.house+'/WATER BILL: Greetings '+waterbill.housename+' :'+waterbill.monthdate+';Previous:'+waterbill.previous+';Current:'+waterbill.current+';UnitCost Kshs.'+waterbill.cost+';Units:'+waterbill.units+';'+(waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':'')+'Total Kshs.'+(waterbill.total+waterbill.total_os)+'.Thank You'}
                                            onChange={handleChange} />
                                            WATER BILL: Greetings {waterbill.housename} :{waterbill.monthdate}; Previous:{waterbill.previous}; Current:{waterbill.current}; Cost Kshs.{waterbill.cost}; Units:{waterbill.units}; {waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':''} Total Kshs.{waterbill.total+waterbill.total_os}.
                                    </label>
                                }
                            </div>
                            :
                            <div className={`unwaterbillvaluesdiv p-1 text-sm border-5 ${(uploadwaterbill.upwaterbill.filter( (f) => f === waterbill.phone+'/'+waterbill.house+'/WATER BILL: Greetings '+waterbill.housename+' :'+waterbill.monthdate+';Previous:'+waterbill.previous+';Current:'+waterbill.current+';UnitCost Kshs.'+waterbill.cost+';Units:'+waterbill.units+';'+(waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':'')+'Total Kshs.'+(waterbill.total+waterbill.total_os)+'.Thank You')).length ?'bg-secondary text-info':'bg-light text-dark'}`} >
                            {allchecked?
                                <label className="selwaterbill-1 col-12 m-0 p-1">
                                    <input type="checkbox" 
                                        className="selectedwaterbilltenants mr-1" 
                                        name="waterbillvalues[]"
                                        checked={allchecked}
                                        value={waterbill.phone+'/'+waterbill.house+'/WATER BILL: Greetings '+waterbill.housename+' :'+waterbill.monthdate+';Previous:'+waterbill.previous+';Current:'+waterbill.current+';UnitCost Kshs.'+waterbill.cost+';Units:'+waterbill.units+';'+(waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':'')+'Total Kshs.'+(waterbill.total+waterbill.total_os)+'.Thank You'}
                                        onChange={handleChange} />
                                        WATER BILL: Greetings {waterbill.housename} :{waterbill.monthdate}; Previous:{waterbill.previous}; Current:{waterbill.current}; Cost Kshs.{waterbill.cost}; Units:{waterbill.units}; {waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':''} Total Kshs.{waterbill.total+waterbill.total_os}.
                                </label>
                            :
                                <label className="selwaterbill-1 col-12 m-0 p-1">
                                    <input type="checkbox" 
                                        className="selectedwaterbilltenants mr-1" 
                                        name="waterbillvalues[]"
                                        checked={(uploadwaterbill.upwaterbill.filter( (f) => f === waterbill.phone+'/'+waterbill.house+'/WATER BILL: Greetings '+waterbill.housename+' :'+waterbill.monthdate+';Previous:'+waterbill.previous+';Current:'+waterbill.current+';UnitCost Kshs.'+waterbill.cost+';Units:'+waterbill.units+';'+(waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':'')+'Total Kshs.'+(waterbill.total+waterbill.total_os)+'.Thank You')).length}
                                        value={waterbill.phone+'/'+waterbill.house+'/WATER BILL: Greetings '+waterbill.housename+' :'+waterbill.monthdate+';Previous:'+waterbill.previous+';Current:'+waterbill.current+';UnitCost Kshs.'+waterbill.cost+';Units:'+waterbill.units+';'+(waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':'')+'Total Kshs.'+(waterbill.total+waterbill.total_os)+'.Thank You'}
                                        onChange={handleChange} />
                                        WATER BILL: Greetings {waterbill.housename} :{waterbill.monthdate}; Previous:{waterbill.previous}; Current:{waterbill.current}; Cost Kshs.{waterbill.cost}; Units:{waterbill.units}; {waterbill.total_os>0?'CC:'+waterbill.total+';Other:'+waterbill.total_os+';':''} Total Kshs.{waterbill.total+waterbill.total_os}.
                                </label>
                            }
                            </div>
                        }
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
}

export default WaterbillMessageTable;