import Carousel from 'react-bootstrap/Carousel';

import { Link } from 'react-router-dom';

function LoadWaterbill({waterbill,handleShow}) {
    

  return (
    <Carousel keyboard={true} indicators={true}>
        {waterbill && waterbill.map((waters,key) => (
            <Carousel.Item key={key}>
                <div key={key} className="row m-0 mb-0 ml-4 mr-4 pl-2 pr-2">
                    <div className="col-12 m-0 mb-2 p-1">
                        <div className="card-box mb-1">
                            
                            <div className='card-icon'>
                                <span className="card-box-icon bold bg-light elevation-1">{key+1}.  </span>
                                <span className="card-box-icon bg-light bold elevation-1 mt-1">{waters.plotcode}</span>
                                <span className="card-box-icon bg-light elevation-1 mt-1"><small>Sent:{waters.totalbillsmsghse}</small></span>
                                <span className="card-box-icon bg-light elevation-1 mt-1" style={{"width":"55px"}}><small>Units:{waters.totalunits}</small></span>
                            </div>
                            <div className="card-box-content">
                                {/* <p className='text-xs text-center text-lime mb-2'>{waters.plotname}</p> */}
                                <p className='text-center bg-info text-white elevation-1 border-light p-2 mb-2' style={{"textAlign": "center","whiteSpace": "nowrap","fontSize":"8px"}}>{waters.plotname}</p>
                                <span className="card-box-text"><span className='title-sm bold'>Billed: </span> {waters.totalbillshse}/{waters.totalhouseshse}  </span>
                                <div className="card-box-text"><span className='title-sm bold'>Total: </span> Kshs. {new Number(waters.totals).toFixed(2)} </div>
                                
                            </div>

                            <div className='card-icon'>
                                <div className="card-box-links m-0 p-0">
                                    <div className="row m-0 p-0 justify-content-center">
                                        <div className='col-12 p-0 m-1' title='Upload/Update Waterbill'>
                                            <Link to={'/properties/update/waterbill/'+waters.id+'/'+waters.month} className="p-0 m-0 pl-1 pr-1 btn btn-outline-primary"> <i className='fa fa-upload text-sm'></i></Link>
                                        </div>
                                        
                                        <div className='col-12 p-0 m-1' title='Send/View Waterbill Messages'>
                                            <Link to={'/properties/update/waterbill/'+waters.id+'/'+waters.month} className="p-0 m-0 pl-1 pr-1 btn btn-outline-success"> <i className='fa fa-envelope text-sm'></i></Link>
                                        </div>

                                        <div className='col-12 p-0 m-1' title='Download Waterbill'>
                                            <Link to="#" className="p-0 m-0 pl-1 pr-1 btn btn-outline-secondary" onClick={()=>{handleShow(waters)}}> <i className='fa fa-download text-sm'></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* <div className='card-icon'>
                                <span className="card-box-icon ">
                                    <div className='p-0 m-0'>
                                        <a href="#" className="btn btn-outline-primary p-0 pl-2 pr-2 m-0"> <i className='fa fa-briefcase text-sm'></i></a>
                                    </div>
                                </span>
                                <span className="card-box-icon ">
                                    <div className='p-0 m-0'>
                                        <a href="#" className="btn btn-outline-success p-0 pl-2 pr-2 m-0"> <i className='fa fa-envelope text-sm'></i></a>
                                    </div>
                                </span>
                                <span className="card-box-icon ">
                                    <div className='p-0 m-0'>
                                        <a href="#" className="btn btn-outline-secondary p-0 pl-2 pr-2 m-0" > <i className='fa fa-download text-sm'></i></a>
                                    </div>
                                </span>
                            </div> */}

                        </div>
                        
                    </div>
                </div>
            </Carousel.Item>
        ))
        }
    </Carousel>
  );
}

export default LoadWaterbill;