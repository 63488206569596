import {Link, Navigate, useNavigate} from 'react-router-dom';

function ReminderMessageTable({waterbill,no,preview,uploadwaterbill,allchecked,handleChange,sendMessage,formdata}) {
    let balance='';
    if(waterbill.Balance>0){
        balance='Kindly Pay a Balance of Kshs.'+waterbill.Balance+'.';
    }
    else if(waterbill.Balance<0){
        balance='Overpayment Kshs.'+Math.abs(waterbill.Balance)+'.';
    }
    else if(waterbill.Balance==0){
        balance='You have No Arrears.';
    }

    let paid='';
    if(waterbill.TotalPaid>0){
        paid='Received Ksh.'+waterbill.TotalPaid+'.';
    }

    let rent='';
    if(waterbill.Rent>0){
        rent='Rent Ksh.'+waterbill.Rent+' ';
    }

    let garbage='';
    if(waterbill.Garbage>0){
        garbage='Garbage Ksh.'+waterbill.Garbage+' ';
    }
    
    let waterbilld='';
    if(waterbill.Waterbill>0){
        waterbilld='Waterbill Ksh.'+waterbill.Waterbill+' ';
    }
    
    let housed='';
    if(waterbill.HseDeposit>0){
        housed='Deposit Ksh.'+waterbill.HseDeposit+' ';
    }
    
    let waterd='';
    if(waterbill.Water>0){
        waterd='Water Deposit Ksh.'+waterbill.Water+' ';
    }
    
    let kplcd='';
    if(waterbill.KPLC>0){
        kplcd='KPLC Deposit Ksh.'+waterbill.KPLC+' ';
    }
    
    let lease='';
    if(waterbill.Lease>0){
        lease='Lease Ksh.'+waterbill.Lease+' ';
    }

    // String.prototype.namef =function() { return this.replace(/{fname}/g, waterbill.tenantfname) }
    String.prototype.name =function() { return this.replace(/{name}/g, waterbill.tenantfname) }
    String.prototype.house =function() { return this.replace(/{house}/g, waterbill.housename) }
    String.prototype.balance =function() { return this.replace(/{balance}/g, balance) }
    String.prototype.paid =function() { return this.replace(/{paid}/g, paid) }
    String.prototype.total =function() { return this.replace(/{total}/g, waterbill.TotalUsed) }
    String.prototype.month =function() { return this.replace(/{month}/g, waterbill.monthdate) }
    String.prototype.rent =function() { return this.replace(/{rent}/g, rent) }
    String.prototype.garbage =function() { return this.replace(/{garbage}/g, garbage) }
    String.prototype.waterbilld =function() { return this.replace(/{waterbilld}/g, waterbilld) }
    String.prototype.housed =function() { return this.replace(/{housed}/g, housed) }
    String.prototype.waterd =function() { return this.replace(/{waterd}/g, waterd) }
    String.prototype.kplcd =function() { return this.replace(/{kplcd}/g, kplcd) }
    String.prototype.lease =function() { return this.replace(/{lease}/g, lease) }

    let message=(formdata.Message).name();
    message=(message).house();
    message=(message).balance();
    message=(message).paid();
    message=(message).month();
    message=(message).total();
    message=(message).rent();
    message=(message).garbage();
    message=(message).waterbilld();
    message=(message).housed();
    message=(message).waterd();
    message=(message).kplcd();
    message=(message).lease();
    // document.write("The quick brown fox".name())
  return (
    <div className="col-12 m-0 p-0" >
        <div className="bg-white m-0 mb-2 elevation-3 border-5 p-1" >
            <div className="row m-0 p-0">
                <div className="col-12 m-0 p-0 text-left">
                    <span className="text-dark" style={{"fontSize":"12px"}}>
                        <b>{no+1}.  House: {waterbill.housename}, Name: {waterbill.tenantname},Phone: {waterbill.phone}
                            {waterbill.messageSent !=null?
                                <button className="btn btn-outline-warning float-right text-xs m-1 p-0 pr-1 pl-1" onClick={()=>{sendMessage(waterbill,message)}}><i className="fa fa-history"></i> Resend</button>
                            :
                                <button className="btn btn-outline-info float-right text-xs m-1 p-0 pr-1 pl-1" onClick={()=>{sendMessage(waterbill,message)}}><i className="fa fa-paper-plane"></i> Send</button>
                            }
                        </b>
                        
                        {waterbill.messageSent !=null?
                            <i className="float-right m-1 p-0 pr-1 pl-1 text-muted">{waterbill.messageSent} {waterbill.status == null?'':<span className="text-sm">({waterbill.status})</span>}</i>
                            :
                            <i className="float-right m-1 p-0 pr-1 pl-1 text-muted">Not sent</i>
                        }

                    </span>
                </div>
            </div>
        
            <div className="">
                <div className="row m-0 p-0">
                    <div className="col-12 m-0 p-0 text-left">
                        
                        <div className={`unwaterbillvaluesdiv p-1 text-md-left text-sm border-5 ${(uploadwaterbill.upwaterbill.filter( (f) => f === (formdata.Message == message?waterbill.phone:waterbill.phone+'/'+waterbill.house+'/'+message))).length ?'bg-secondary text-info':'bg-light text-dark'}`} >
                            {allchecked?
                                <label className="selwaterbill-1 col-12 m-0 p-1">
                                    <input type="checkbox" 
                                        className="selectedwaterbilltenants mr-1" 
                                        name="waterbillvalues[]"
                                        checked={allchecked}
                                        value={(formdata.Message == message?waterbill.phone:waterbill.phone+'/'+waterbill.house+'/'+message)}
                                        onChange={handleChange} />
                                        {message}
                                </label>
                            :
                                <label className="selwaterbill-1 col-12 m-0 p-1">
                                    <input type="checkbox" 
                                        className="selectedwaterbilltenants mr-1" 
                                        name="waterbillvalues[]"
                                        checked={(uploadwaterbill.upwaterbill.filter( (f) => f === (formdata.Message == message?waterbill.phone:waterbill.phone+'/'+waterbill.house+'/'+message))).length}
                                        value={(formdata.Message == message?waterbill.phone:waterbill.phone+'/'+waterbill.house+'/'+message)}
                                        onChange={handleChange} />
                                        {message}
                                </label>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
  );
}

export default ReminderMessageTable;